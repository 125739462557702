import store from '@/state/store.js';
import BillingHistoryChart from '@/views/dashboard/main/BillingHistoryChart.vue';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Message types reports',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    BillingHistoryChart
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    messageTypeCategories() {
      return store.getters['admin/reporting/messageTypeCategories'];
    },
    messageTypesCount() {
      return store.getters['admin/reporting/messageTypesCount'];
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch('admin/reporting/getMessageTypes').finally(() => this.loading = false);
  }
};