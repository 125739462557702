import { render, staticRenderFns } from "./type.vue?vue&type=template&id=24353c98&scoped=true"
import script from "./type.vue?vue&type=script&lang=js"
export * from "./type.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24353c98",
  null
  
)

export default component.exports